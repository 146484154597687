<template>
  <div>
    <!-- <Navbar title="邀请成员" type="1" /> -->
    <div class="invite">
      <div class="camp"></div>
      <div class="line"></div>
      <div class="content">
        <p class="camp-name">
          {{ inviteInfo.name }}
        </p>
        <p class="slogan">
          {{ inviteInfo.slogan }}
        </p>
        <div class="sec-view flex flex-middle-center">
          <div class="sec-line"></div>
          <p class="sec-title">营训信息</p>
          <div class="sec-line"></div>
        </div>

        <div class="info-view flex">
          <img class="info-icon" src="./images/icon_camp_area.png" alt="" />
          <p class="info-title">营训地区:</p>
          <p class="info-title p-left">
            {{ inviteInfo.provinceName || "-" }}{{ inviteInfo.cityName || "-" }}
          </p>
        </div>
        <div class="info-view flex">
          <img class="info-icon" src="./images/icon_camp_time.png" alt="" />
          <p class="info-title">营训时间:</p>
          <p class="info-title p-left">
            {{ inviteInfo.startTime || "-" }} 至 {{ inviteInfo.endTime || "-" }}
          </p>
        </div>
        <div class="info-view flex">
          <img class="info-icon" src="./images/icon_camp_dz.png" alt="" />
          <p class="info-title">营训队长:</p>
          <p class="info-title p-left">{{ inviteInfo.agentName || "-" }}</p>
        </div>

        <div class="sec-view flex flex-middle-center">
          <div class="sec-line"></div>
          <p class="sec-title">扫码加入</p>
          <div class="sec-line"></div>
        </div>
        <div class="code-view">
          <img class="qr-code" :src="qrcode" alt="" />
        </div>
      </div>
      <div>
        <div class="btn orange" @click="applyClick">立即报名参营</div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  name: "inviteMember",
  data() {
    return {
      qrcode: null,
      inviteInfo: {},
      id: '',
    };
  },
  created() {
    // let info = this.$route.query.inviteInfo;
    // this.inviteInfo = JSON.parse(decodeURIComponent(info));
    // this.createQrCode(this.inviteInfo.id)
    // console.log(
    //   "%c 🍠 this.inviteInfo: ",
    //   "font-size:20px;background-color: #465975;color:#fff;",
    //   this.inviteInfo
    // );
    this.id = this.$route.query.id
    console.log('%c 🥒 this.id: ', 'font-size:20px;background-color: #7F2B82;color:#fff;', this.id);
    this.loadInviteInfo()

  },
  methods: {
    async loadInviteInfo() {
      try {
        const res = await this.$api.training.market_camp_invite_info({
          id: this.id,
        })
        this.inviteInfo = res.data.data
        this.createQrCode(this.inviteInfo.id)
      } catch (err) {
        console.log('err====', err)
      }
    },
     async createQrCode(id) {
      this.qrcode = await QRCode.toDataURL(id, {
        margin: 0,
      })
    },
    applyClick() {
      this.$toast("仅限APP扫码加入");
    },
  },
};
</script>

<style lang="scss" scoped>
.invite {
  position: relative;
  height: 100vh;
  // height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  background: url("./images/invite_bg.png") 0 0 no-repeat;
  background-size: cover;
  padding: 127px 20px 40px;

  .camp {
    position: absolute;
    top: 77px;
    left: 20px;
    padding-top: 77px;
    width: calc(100vw - 40px);
    height: 196px;
    box-sizing: border-box;
    background: url("./images/invite_camp_top.png") 0 0 no-repeat;
    background-size: cover;
    padding: 50px 8px 0;
  }

  .line {
    margin: 0 8px;
    width: calc(100vw - 56px);
    height: 24px;
    box-sizing: border-box;
    background: url("./images/invite_line.png") 0 0 no-repeat;
    background-size: cover;
  }

  .content {
    margin: -1px 8px;
    width: calc(100vw - 86px);
    min-height: 150px;
    border-radius: 0 0 4px 4px;
    background-color: #fbd4a0;
    padding: 6px 15px 26px;
    margin-bottom: 16px;

    .camp-name {
      color: #320e0a;
      font-size: 18px;
      font-weight: 600;
    }

    .slogan {
      padding-top: 2px;
      color: #784123;
      font-size: 12px;
    }

    .info-view {
      padding-bottom: 10px;

      .info-icon {
        width: 16px;
        height: 17px;
        padding-right: 16px;
      }

      .info-title {
        color: #320e0a;
        font-size: 12px;
        font-weight: 500;
      }

      .p-left {
        padding-left: 8px;
      }
    }

    .sec-view {
      margin: 12px 0;

      .sec-line {
        width: 103px;
        height: 0.5px;
        opacity: 0.2;
        background-color: #38140e;
      }

      .sec-title {
        color: #320e0a;
        font-size: 14px;
        font-weight: 600;
      }
    }

    .code-view {
      margin: 0 auto;
      padding: 6px;
      width: 100px;
      height: 100px;
      background-color: #fff;
      text-align: center;
      border-radius: 6px;

      .qr-code {
        width: 100px;
        height: 100px;
      }
    }

    .introduce-view {
      padding: 12px 12px 26px;
      background-color: #fbdcb3;
      border: 1px solid #d2ab82;
      border-radius: 4px;
      color: #320e0a;
      font-size: 12px;
    }
  }

  .btn {
    margin: 0 auto;
    width: calc(100vw - 56px);
    height: 44px;
    line-height: 44px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  .gray {
    box-sizing: border-box;
    background: url("./images/btn_bg_gray.png") 0 0 no-repeat;
    background-size: cover;
    color: #4e6f87;
  }

  .orange {
    box-sizing: border-box;
    background: url("./images/btn_bg_orange.png") 0 0 no-repeat;
    background-size: cover;
    color: #fff;
  }
}
</style>